$(document).ready(function() {

	// SCROLL PAGE EVENT

	var $window = $(window);
	$window.on('load scroll', function() {
		var top = $window.scrollTop();

		if (top > 10) {
			$('header').addClass('active');
		} else {
			$('header').removeClass('active');
		}
	});





	/*==================================
			Single Product Thumbnail JS
   ===================================*/

	if ($('.product_wrap').length) {

		$('.product_wrap .top_block').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			rtl: true,
			arrows: false,
			dots: false,
			infinite: true,
			asNavFor: '.product_wrap .bottom_block'
		});

		$('.product_wrap .bottom_block').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			infinite: true,
			dots: false,
			asNavFor: '.product_wrap .top_block',
			rtl: true,
			focusOnSelect: true,
			responsive: [{
				breakpoint: 769,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					rtl: true,
				}
			}]
		});

	}

	/*==================================
			      Fancy Box JS
   ===================================*/

	if ($('[data-fancybox]').length) {
		$('[data-fancybox]').fancybox({});
	}

	$(".sf-menu").superfish({
		delay: 200,
		speed: "fast",
		cssArrows: false
	}).after("<div id='mobile-menu'>").clone().appendTo('#mobile-menu');
	$('#mobile-menu').find("*").attr("style", "");
	$('#mobile-menu').children("ul").removeClass("sf-menu")
		.parent().mmenu({
			extensions: ['widescreen', 'theme-qite', 'effect-menu-slide', 'pagedim-black', 'position-top'],
			navbar: {
				title: "תפריט"
			}
		});


	$(".toggle-mnu").click(function() {
		$(this).addClass("on");
	});

	$(".mm-slideout").on('click', function() {
		$(".toggle-mnu").removeClass("on");
	})

	$('.basket').on('click', function() {
		$('.mini__cart').css('display', 'block')
	});

	$('#close__mini').on('click', function() {
		$('.mini__cart').css('display', 'none')
	});

});