// $(document).ready(function() {
//   $("#itemGallery").lightGallery({
//     selector: '.product-item-gallery',
//     useLeft: true,
//     thumbnail: true,
//     animateThumb: false,
//     showThumbByDefault: false,
//     zoom: false,
//   });
// });


// ##################################################

function getTimeRemaining(endtime) {
  var t = endtime - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(id, endtime) {
  var clock = document.getElementById(id);
  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    var t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
      $('.wrapper-clockdiv').css('display', 'none');
      location.reload();
      return false;
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}


$(function() {

  if ($('#clockdiv').length > 0) {
    var timedate = $('#clockdiv').data('timedate');
    if(timedate) {
      $('.wrapper-clockdiv').fadeIn();
    }

    var countDownDate = new Date(timedate).getTime();
    // var deadline = new Date(Date.parse(new Date()) + 5 * 24 * 60 * 60 * 1000);

    initializeClock('clockdiv', countDownDate); 
  }

  /**
   * Update Price of more items and add/remove images
   */


  $(".update-buy-btn").change(function() { // buy togather
    var price = 0;

    $(this).closest('.section-recommended-package').find('.recommended-package-list-prod__item_show').last().attr('data-content-css','+');
    
    $(".update-buy-btn").each(function(){
      var em = $(this);
      if(this.checked){
          price += em.data("price");
          $(".recommended-package-list-prod__item[data-item-cs='"+ em.data("item-cs") + "']").fadeIn();
          $(".recommended-package-list-prod__item[data-item-cs='"+ em.data("item-cs") + "']").addClass('recommended-package-list-prod__item_show');
      }
      else{
        $(".recommended-package-list-prod__item[data-item-cs='"+ em.data("item-cs") + "']").fadeOut();
        $(".recommended-package-list-prod__item[data-item-cs='"+ em.data("item-cs") + "']").removeClass('recommended-package-list-prod__item_show');
      }

    });
    $(".update-price-on-buy").text(price.toFixed(0,2));

    $(this).closest('.section-recommended-package').find('.recommended-package-list-prod__item_show').last().attr('data-content-css','');

	});
	
});